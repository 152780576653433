html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Devanagari:wght@100;200;300;400;500;600;700&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

:root {
  color: white;
  --background: #ffffff;
  --link-color: #ffffff3e;
  --btn-color: #0d1324;
  font-size: 24px;
  font-family: "IBM Plex Sans Devanagari", sans-serif;
}

main {
  background-color: var(--background);
  height: 100vh;
}

#contact {
  position: absolute;
  top: 1rem;
  left: 1rem;
  text-decoration: none;
}

#contact > img {
  filter: invert();
  opacity: 0.5;
  mix-blend-mode: exclusion;
  background-color: none;
  transition: 0.2s;
  width: 1rem;
}
#contact > img:hover {
  opacity: 1;
  scale: 1.1;
}

.element-container {
  display: grid;
  grid-template: 0.5fr 1fr 1fr/ 1fr 1fr 1fr 1.7fr;
  grid-template-areas:
    "title title title proj"
    "about about about proj"
    "info info null proj";
  height: 100%;
  width: 100%;
}

.hero-title {
  font-family: "Lato", sans-serif;
  z-index: 5;
  grid-area: title;
  font-size: 2.5em;
  font-weight: 100;
  letter-spacing: 1.3rem;
  margin: auto;
}

.about-container {
  display: flex;
  grid-area: about;
  font-size: 0.9rem;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-evenly;
}

.about-container > img {
  width: 10rem;
  height: 10rem;
  object-fit: cover;
  mix-blend-mode: screen;
  opacity: 100%;
}
.about-container > p {
  font-size: 0.8rem;
  z-index: 5;
  width: 50%;
  line-height: 1.7rem;
  font-weight: 300;
}

.hero-background {
  background: #000000;
  background: linear-gradient(104deg, #fffff0 0%, #f3f0db 65%, #00d4ff00 65%);
  mix-blend-mode: difference;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}
.overlay {
  background: #000000;
  mask-image: linear-gradient(
    104deg,
    rgb(0, 0, 0) 0%,
    rgb(0, 0, 0) 65%,
    rgba(165, 165, 165, 0) 65%
  );
  mix-blend-mode: multiply;
  position: absolute;
  opacity: 5%;
  width: 100%;
  height: 100%;
  top: 0;
}

.project-container {
  z-index: 100;
  grid-area: proj;
  padding: 0 3vw;
  height: 100vh;
  overflow: scroll;
  width: 30vw;
}

.project {
  aspect-ratio: 16 / 9;
  width: 100%;
  background-size: 100%;
  margin: 5vh 0;
  transition: 0.1s;
  filter: brightness(90%);
  filter: grayscale(100);
}

.placeholder {
  background-color: var(--btn-color);
  aspect-ratio: 16 / 9;
}

.placeholder > h2 {
  font-weight: 700;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 2rem;
  top: 40%;
  opacity: 0.1;
}

.project:hover {
  scale: 1.03;
  filter: brightness(100%);
}

.stack-container {
  display: flex;
  flex-direction: column;
  margin: 0 0 0 35%;
  justify-content: space-evenly;
  height: 80%;
  width: 20vw;
}

.stack-group {
  width: 100%;
  z-index: 5;
}

.stack-group > h2 {
  font-size: 0.6rem;
  text-align: left;
  font-weight: 700;
  letter-spacing: 0.3rem;
  margin-left: 0.1rem;
}

.stack-item {
  width: 1.6rem;
  height: 1.6rem;
  margin: 0.5rem 0.2rem 0 0;
  opacity: 30%;
  padding: 0 0.1rem;
  filter: grayscale(100%);
  transition: 0.1s;
}

.stack-item:hover {
  transform: translateY(-0.1rem);
  scale: 1.05;
  filter: grayscale(0);
  opacity: 70%;
}

@media (orientation: portrait) {
  #contact {
    left: 3vw;
  }

  #contact > img {
    width: 0.8rem;
  }
  .hero-title {
    font-size: 5vw;
    font-weight: 300;
  }
  .hero-background {
    background: #000000;
    background: linear-gradient(170deg, #fffff0 0%, #f3f0db 80%, #00d4ff00 80%);
    width: 100%;
    height: 120vw;
    top: 0;
  }

  .overlay {
    background: #000000;
    mask-image: linear-gradient(
      170deg,
      rgb(0, 0, 0) 0%,
      rgb(0, 0, 0) 80%,
      rgba(165, 165, 165, 0) 80%
    );
    width: 100%;
    height: 120vw;
  }

  .about-container > img {
    width: 20vw;
    height: 20vw;
  }
  .about-container > p {
    width: 60vw;
    line-height: 3vh;
    font-weight: 300;
    font-size: 1.8vw;
    text-align: center;
  }

  .element-container {
    grid-template: 20vw 40vw 30vw 1fr/ 1fr;
    grid-template-areas:
      "title title"
      "about about"
      "info info"
      "proj proj";
    height: 100%;
    width: 100%;
  }
  .stack-container {
    margin: 0;
    text-align: center;
    height: 100%;
    width: 100vw;
  }
  .stack-group > h2 {
    text-align: center;
    font-size: 1.5vw;
  }

  .stack-item {
    width: 4vw;
    height: 4vw;
  }

  .project-container {
    overflow: visible;
    width: 94vw;
  }
  .project {
    filter: brightness(100%);
    filter: grayscale(0);
  }

  .project:hover {
    scale: 1;
  }

  @media only screen and (max-width: 850px) {
    /* For Phones: */
    .hero-title {
      /* font-size: 1rem; */
      letter-spacing: 0.4rem;
      font-weight: 400;
    }
    .hero-background {
      background: #000000;
      background: linear-gradient(
        180deg,
        #fffff0 0%,
        #f3f0db 100%,
        #00d4ff00 100%
      );
      width: 100vw;
      height: 100%;
      top: 0;
    }

    .overlay {
      background: #000000;
      mask-image: linear-gradient(
        180deg,
        rgb(0, 0, 0) 0%,
        rgb(0, 0, 0) 100%,
        rgba(165, 165, 165, 0) 100%
      );
      width: 100vw;
      height: 100%;
    }

    .about-container > img {
      width: 40vw;
      height: 40vw;
    }
    .about-container > p {
      width: 80vw;
      line-height: 5vw;
      font-weight: 300;
      font-size: 2.5vw;
      text-align: center;
    }

    .about-container {
      flex-direction: column;
    }

    .element-container {
      display: grid;
      grid-template: 15vw 80vw 30vw 1fr/ 1fr;
      grid-template-areas:
        "title"
        "about"
        "info"
        "proj";
      height: 100%;
      width: 100%;
    }
    .project-container {
      overflow: visible;
      width: 94vw;
    }
    .stack-container {
      background-color: none;
      margin: 0;
      text-align: center;
      height: 100%;
    }

    .stack-item {
      width: 5vw;
      height: 5vw;
    }
    .stack-group > h2 {
      text-align: center;
      font-size: 2vw;
    }
    .project {
      filter: brightness(100%);
      filter: grayscale(0);
    }

    .project:hover {
      scale: 1;
    }
  }
}
@media (orientation: landscape) {
  @media only screen and (max-width: 1600px) {
    .hero-title {
      font-size: 2rem;
      letter-spacing: 1rem;
    }

    .about-container > img {
      margin-left: 2rem;
    }

    .about-container > p {
      width: 80%;
      font-size: 0.8rem;
      padding: 1rem;
    }
    .about-container > p {
      font-size: 0.7rem;
      line-height: 1.3rem;
      width: 50%;
    }
  }

  @media only screen and (max-width: 1300px) {
    .about-container > img {
      width: 10rem;
      height: 10rem;
      margin-left: 2vw;
    }

    .about-container > p {
      width: 60%;
      font-weight: 300;
      font-size: 0.6rem;
      line-height: 1rem;
      text-align: center;
      padding: 1rem 5rem 1rem 1rem;
    }

    .hero-title {
      font-size: 1rem;
      letter-spacing: 1rem;
      font-weight: 300;
      padding: 0.2rem;
    }

    .stack-container {
      display: flex;
      flex-direction: row;
      margin: 0 0 0 10%;
      justify-content: space-evenly;
      height: 80%;
      width: 50vw;
      align-items: center;
    }

    .stack-group > h2 {
      font-size: 0.4rem;
    }

    .stack-item {
      width: 1.2rem;
      height: 1.2rem;
      margin: 0.5rem 0.2rem 0 0;
      opacity: 30%;
      padding: 0 0.1rem;
      filter: grayscale(100%);
      transition: 0.1s;
    }
  }

  @media only screen and (max-width: 900px) {
    .hero-title {
      font-size: 0.8rem;
      font-weight: 500;
      letter-spacing: 0.8rem;
      margin: 0.5rem auto;
    }

    .about-container > img {
      width: 20vw;
      height: 40vh;
      margin-left: 2rem;
      margin: 0;
    }

    .about-container > p {
      width: 60%;
      font-weight: 300;
      font-size: 0.5rem;
      line-height: 1rem;
      text-align: center;
      padding: 1rem 5rem 1rem 1rem;
    }

    .stack-group > h2 {
      font-size: 0.4rem;
      text-align: left;
      font-weight: 700;
      letter-spacing: 0.1rem;
    }

    .stack-item {
      width: 1rem;
      height: 1rem;
      margin: 0.3rem 0.1rem;
      opacity: 30%;
      filter: grayscale(100%);
    }
  }
}
